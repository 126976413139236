import React from "react"

import { Form, Formik } from "formik"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { navigate } from "gatsby-plugin-intl"
import { ellipsis, lighten } from "polished"
import { AiFillEdit as EditIcon } from "react-icons/ai"

import {
  Alert,
  Input,
  LoadingButton,
  P,
  shevy,
  SplitImage,
  theme,
  toEm,
  Wrapper,
} from "../components"
import { UnlabeledFormikField } from "../components/form"
import SEO from "../components/Seo"
import { auth, Firebase } from "../firebase"
import { Heading } from "../logged-in/components"
import { useFirebaseLogin } from "../utils/firebase-login"
import { useTranslations } from "../utils/intl"

const bp = "@media(min-width: 800px)"

const Login: React.FC = () => {
  const { it } = useTranslations()

  const {
    file: { childImageSharp: image },
  } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "laughing-grill.png" }) {
        childImageSharp {
          gatsbyImageData(
            sizes: "max(50vw, 100vh)"
            quality: 80
            placeholder: TRACED_SVG
            tracedSVGOptions: { background: "#77B4FF", color: "#F6F6F6" }
            breakpoints: [960, 1024, 1920, 2100]
          )
        }
      }
    }
  `)
  return (
    <Wrapper>
      <SEO title={it(["Log in", "Logga in"])}></SEO>
      <SplitImage
        image={<GatsbyImage alt="Laughing girl" image={getImage(image)!} />}
        css={{ backgroundColor: theme.colors.grey }}
      >
        <LoginBox
          css={{
            margin: "0 auto",
            maxWidth: toEm(500),
            padding: toEm(24),

            [bp]: {
              padding: toEm(32),
              marginTop: `max(0px, calc(100vh/2 - 454px / 2))`,
            },
          }}
        />
      </SplitImage>
    </Wrapper>
  )
}

const LoginBox: React.FC = ({ ...props }) => {
  const { it } = useTranslations()

  const locationParams = React.useMemo(
    () =>
      typeof window !== "undefined"
        ? new URLSearchParams(window.location?.search)
        : undefined,
    []
  )

  const redirect = locationParams?.get("redirect")

  const {
    user,
    loading,
    error,
    signin,
    signup,
    checkEmail,
    sendPasswordResetMail,
  } = useFirebaseLogin()

  const [state, setState] = React.useState<{
    show: "email" | "login" | "signup" | "restore"
    email: string
  }>({
    show: "email",
    email: "",
  })

  const onBack = () => {
    setState(s => ({ ...s, show: "email" }))
  }

  const onEmail = React.useCallback(
    async (email: string) => {
      if (await checkEmail(email)) {
        setState({ email, show: "login" })
      } else {
        setState({ email, show: "signup" })
      }
    },
    [checkEmail]
  )

  React.useEffect(() => {
    const signinEmail = locationParams?.get("email")
    if (signinEmail) {
      onEmail(signinEmail)
    }
  }, [locationParams, onEmail])

  const onSignin = (password: string) => {
    signin(state.email, password)
  }

  const onSignup = (password: string) => {
    signup(state.email, password)
  }

  const onRestore = (email: string) => {
    if (
      confirm(
        it([
          `Vill du skicka en återställnings-länk till ${email}?`,
          `Send password reset link to ${email}?`,
        ])
      )
    ) {
      sendPasswordResetMail(
        email,
        `${location.protocol}//${location.host}${location.pathname}?email=${email}`
      ).then(() => {
        setState(s => ({ ...s, show: "restore" }))
      })
    }
  }

  if (user) {
    navigate(redirect ? redirect : "/logged-in", { replace: true })
    return <div />
  }

  return (
    <div
      css={{
        color: theme.colors.black,
      }}
      {...props}
    >
      {state.show == "email" ? (
        <EmailStep email={state.email} loading={loading} onEmail={onEmail} />
      ) : state.show == "login" ? (
        <LoginStep
          onBack={onBack}
          loading={loading}
          onPassword={onSignin}
          onRestore={onRestore}
          email={state.email}
        />
      ) : state.show == "signup" ? (
        <SignupStep
          onBack={onBack}
          loading={loading}
          onPassword={onSignup}
          email={state.email}
        />
      ) : (
        <RestoreLinkSent email={state.email} />
      )}

      {error && <Alert css={{ marginTop: shevy.baseSpacing() }}>{error}</Alert>}
    </div>
  )
}

const EmailStep: React.FC<{
  email: string
  onEmail: (email: string) => void
  loading: boolean
}> = ({ email, onEmail, loading, ...props }) => {
  const { it } = useTranslations()

  return (
    <div {...props}>
      <Formik
        initialValues={{ email }}
        onSubmit={({ email }) => {
          onEmail(email)
        }}
      >
        {() => (
          <Form>
            <Heading
              css={{ marginBottom: shevy.baseSpacing() }}
              title={it(["Enter your email", "Ange din e-post"])}
              subtitle={it([
                "Sign in or create an account",
                "Logga in eller skapa ett konto",
              ])}
            />

            <UnlabeledFormikField
              placeholder={it(["Email", "E-post"])}
              label={it(["Email", "E-post"])}
              name="email"
              type="email"
              autoComplete="username"
              autoFocus
              required
            />
            <LoadingButton
              type="submit"
              disabled={loading}
              css={{
                marginTop: shevy.baseSpacing(),
                color: theme.colors.white,
              }}
              variant="filled"
              color={theme.colors.green}
            >
              {it(["Next", "Nästa"])}
            </LoadingButton>
          </Form>
        )}
      </Formik>
      <img
        onClick={() => {
          const provider = new Firebase.auth.OAuthProvider("apple.com")
          auth
            .signInWithRedirect(provider)
            // .signInWithPopup(provider)
            .then()
            .catch(e => {
              console.error(e)
              throw e
            })
        }}
        css={{ width: "100%", marginTop: shevy.baseSpacing(2) }}
        src={it([
          require("../images/appleid_button_en@2x.png").default,
          require("../images/appleid_button_sv@2x.png").default,
        ])}
      />
    </div>
  )
}

const LoginStep: React.FC<{
  onPassword: (email: string) => void
  onRestore: (email: string) => void
  onBack: () => void
  loading: boolean
  email: string
}> = ({ email, onPassword, onRestore, onBack, loading, ...props }) => {
  const { it } = useTranslations()

  return (
    <div {...props}>
      <Formik
        initialValues={{ password: "" }}
        onSubmit={({ password }) => {
          onPassword(password)
        }}
      >
        {() => (
          <Form>
            <Heading
              css={{ marginBottom: shevy.baseSpacing() }}
              title={it(["Sign in", "Logga in"])}
              subtitle={it(["Enter your password.", "Ange ditt lösenord."])}
            />
            <P>
              <em
                css={{
                  cursor: "pointer",
                  ":hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  onRestore(email)
                }}
              >
                {it(["Forgot password?", "Glömt lösenord?"])}
              </em>
            </P>
            <EditableField value={email} onBack={onBack} />
            <UnlabeledFormikField
              css={{
                marginTop: shevy.baseSpacing(0.5),
              }}
              placeholder={it(["Password", "Lösenord"])}
              label={it(["Password", "Lösenord"])}
              name="password"
              type="password"
              autoComplete="current-password"
              autoFocus
              required
            />
            <LoadingButton
              type="submit"
              css={{
                marginTop: shevy.baseSpacing(),
                color: theme.colors.white,
              }}
              variant="filled"
              disabled={loading}
              color={theme.colors.green}
            >
              {it(["Sign in", "Logga in"])}
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const SignupStep: React.FC<{
  onPassword: (email: string) => void
  onBack: () => void
  loading: boolean
  email: string
}> = ({ email, onPassword, onBack, loading, ...props }) => {
  const { it } = useTranslations()

  return (
    <div {...props}>
      <Formik
        initialValues={{ password: "" }}
        onSubmit={({ password }) => {
          onPassword(password)
        }}
      >
        {() => (
          <Form>
            <Heading
              css={{ marginBottom: shevy.baseSpacing() }}
              title={it(["Create an account", "Skapa ett konto"])}
              subtitle={it(["Choose a password", "Skapa ett lösenord"])}
            />
            <EditableField value={email} onBack={onBack} />
            <UnlabeledFormikField
              css={{
                marginTop: shevy.baseSpacing(0.5),
              }}
              placeholder={it(["Password", "Lösenord"])}
              label={it(["Password", "Lösenord"])}
              name="password"
              type="password"
              autoComplete="new-password"
              autoFocus
              required
            />
            <LoadingButton
              type="submit"
              css={{
                marginTop: shevy.baseSpacing(),
                color: theme.colors.white,
              }}
              variant="filled"
              disabled={loading}
              color={theme.colors.green}
            >
              {it(["Create account", "Skapa konto"])}
            </LoadingButton>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const RestoreLinkSent: React.FC<{ email: string }> = ({ email, ...props }) => {
  const { it } = useTranslations()

  return (
    <div {...props}>
      <Heading
        title={it(["Restore link sent", "Återställningslänk skickad"])}
        subtitle={it([
          `Please check your inbox for ${email}`,
          `Vänligen titta i din inkorg för ${email}`,
        ])}
      />
    </div>
  )
}

const EditableField: React.FC<{ value: string; onBack: () => void }> = ({
  value,
  onBack,
}) => (
  <div css={{ position: "relative" }}>
    <Input
      css={{
        display: "block",
        width: "100%",
        ...ellipsis("500px"),
        paddingRight: toEm(60 - 10),

        // Fix for Safari+iOS
        opacity: 1,
        WebkitTextFillColor: theme.colors.black,
      }}
      autoComplete="username"
      disabled
      value={value}
    />
    <div
      css={{
        width: toEm(60),
        position: "absolute",
        display: "flex",
        top: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        ":hover": {
          color: lighten(0.1, theme.colors.black),
        },
      }}
      // TODO:
      // https://github.com/adobe-private/react-spectrum-v3/blob/master/packages/%40react-aria/button/docs/useButton.mdx
      onClick={() => onBack()}
    >
      <EditIcon size={toEm(25)} />
    </div>
  </div>
)

export default Login
